<template>
  <div class="text-image-tile-grid">
    <UiHeader
      :header="header"
      :header-position="headerPosition"
      :header-size="headerSize"
      :header-layout="headerLayout"
      :subheader="subheader"
    />

    <div v-if="itemList.length" class="text-image-tile-grid__grid">
      <div
        v-for="item in itemList"
        :key="item.id"
        class="text-image-tile-grid__item"
        :class="itemThemeClass(item)"
      >
        <UiImg
          :image="item.image"
          use-image-size
          class="text-image-tile-grid__item-image"
        />

        <div
          v-if="item.name"
          class="text-image-tile-grid__item-name"
          v-text="item.name"
        />

        <T3HtmlParser
          v-if="item.text"
          :content="item.text"
          class="text-image-tile-grid__item-text"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesItemProps, UiCeProductBoxesProps } from '~/types'
import { UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

defineProps<UiCeProductBoxesProps>()

const itemThemeClass = (item: UiCeProductBoxesItemProps) =>
  useTheming(item.variant || 'white', 'theme')
</script>

<style lang="scss">
.text-image-tile-grid {
  & .ui-header {
    &__title {
      margin-bottom: rem(10px);
    }

    &__subtitle {
      text-align: var(--alignment);
    }
  }

  &__grid {
    display: grid;
    gap: rem(24px);

    @include media-query(md) {
      gap: rem(32px);
    }

    @include media-query(sm) {
      grid-template-columns: repeat(30, 1fr);
    }
  }

  &__item {
    display: grid;
    grid-template-rows: repeat(3, auto);
    background-color: var(--theme__theme-color);
    overflow: hidden;
    border-radius: em(8px);
    position: relative;
    isolation: isolate;
    padding-block: rem(24px);
    box-shadow: 0px 1px 2px color(grey-250);

    @include media-query(sm) {
      grid-column: span 13;

      &:nth-child(4n + 1),
      &:nth-child(4n) {
        grid-column: span 17;
      }
    }

    &:has(img) {
      padding-top: 0;
    }

    &::before {
      content: '';
      grid-row: 1 / -2;
      position: absolute;
      top: 30%;
      inset: 0;
      background-image: linear-gradient(
        to top,
        var(--theme__theme-color, color(white)),
        transparent 0%
      );
      z-index: z-index(neutral);
      pointer-events: none;
    }

    &-image {
      object-fit: cover;
      width: 100%;
      position: relative;
      z-index: z-index(below);
    }

    &-name {
      font-size: rem(20px);
      font-weight: 600;
      color: var(--theme__font-color);

      @include media-query(md) {
        font-size: rem(24px);
      }
    }

    &-text {
      color: var(--theme__font-color--text);

      & > * {
        font-size: rem(14px);

        @include media-query(md) {
          font-size: rem(16px);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.theme-white &-name {
      color: color(grey-800);
    }

    &.theme-white &-text {
      color: color(grey-550);
    }

    &-name,
    &-text {
      position: relative;
      z-index: z-index(over);
      padding-inline: rem(24px);
    }

    &-name + &-text {
      margin-top: rem(12px);
    }
  }

  & .ui-header + &__grid {
    margin-top: rem(64px);
  }
}
</style>
